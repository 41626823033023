import type { Dropdown } from 'bootstrap';

export default (
  dropdownToggle: MaybeRef<HTMLElement>,
  dropdownContainer: MaybeRef<HTMLElement>,
  options?: Partial<Dropdown.Options>,
  handleDropdownShown = () => undefined,
  handleDropdownHidden = () => undefined,
) => {
  const dropdownInstance = ref<Dropdown>(null);
  const dropdownMenuShown = ref(false);
  const instanceAvailable = ref(false);

  /**
   * Handler for dropdown shown event
   *
   *
   * Contains a workaround for a very annoying bug in bootstrap + vue.
   * Following requirements have to be met:
   *  * value binding in the template with `dropdownMenuShown` ref in a child of the dropdown toggle
   *  * value binding has to be on class, style or any attribute or prop
   *  * you have to click on this child with the value binding
   *
   * After the hydration step, if you click on this child, the dropdown menu will not be shown,
   * because Vue restores the classes of the dropdown-menu to the original state.
   * The state of `dropdownMenuShown` is still correct and the inline styles set by PopperJS are still applied.
   * You have to click on the dropdown toggle again in order to show the dropdown menu and restore the expected behaviour.
   *
   * The workaround checks if after the next tick of vue, if the dropdown menu has the `show` class.
   * This means after the value binding applied in the template.
   * If the `show` class is still not available, apply this class manually.
   */
  const handleBsDropdownShown = async () => {
    dropdownMenuShown.value = true;

    await nextTick();

    const container = isRef(dropdownContainer)
      ? dropdownContainer.value
      : dropdownContainer;

    if (container != null) {
      const dropdownMenuContainer = container.querySelector('.dropdown-menu');
      if (
        dropdownMenuContainer != null &&
        !dropdownMenuContainer.classList.contains('show')
      ) {
        dropdownMenuContainer.classList.add('show');
      }
    }

    handleDropdownShown();
  };

  const handleBsDropdownHidden = () => {
    dropdownMenuShown.value = false;

    handleDropdownHidden();
  };

  useEventListener(
    dropdownContainer,
    'shown.bs.dropdown',
    handleBsDropdownShown,
  );

  useEventListener(
    dropdownContainer,
    'hidden.bs.dropdown',
    handleBsDropdownHidden,
  );

  onMounted(async () => {
    const { default: Dropdown } = await import('bootstrap/js/dist/dropdown');
    dropdownInstance.value = new Dropdown(
      isRef(dropdownToggle) ? dropdownToggle.value : dropdownToggle,
      options,
    );
    instanceAvailable.value = true;
  });

  onUnmounted(() => {
    dropdownInstance.value.dispose();
  });
  return {
    dropdownInstance,
    dropdownMenuShown,
    instanceAvailable,
    handleBsDropdownShown,
    handleBsDropdownHidden,
  };
};
